import React,{useState} from "react";
import BackgroundImage from 'gatsby-background-image';
import Image from 'gatsby-image';
import Layout from "../share/layout";
import VideoIcon from '../../images/youtubeicon.svg'
import { useLocation } from "@reach/router"
import Modal from 'react-bootstrap/Modal';
import { Link } from 'gatsby';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import ShareButton from "../share/ShareButton";

const Template3part1 = ({ pageContext }) => {
    let location = useLocation();

    const QueryResultData = pageContext.data;

    var normalize = (function() {
        var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç,", 
        to   = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
        mapping = {};
        
        for(var i = 0, j = from.length; i < j; i++ )
        mapping[ from.charAt( i ) ] = to.charAt( i );
        
        return function( str ) {
            var ret = [];
            for( var i = 0, j = str.length; i < j; i++ ) {
                var c = str.charAt( i );
                if( mapping.hasOwnProperty( str.charAt( i ) ) )
                ret.push( mapping[ c ] );
                else if ((str[i]===" "))
                ret.push( '-' );
                else if ((str.length-1 === i) && (str[str.length-1]===" "))
                ret.push( '-' );
                else
                ret.push( c );
            }      
            return ret.join( '' );
        }
    })();

    const [showModalUno, setShowModalUno] = useState(false);
    const [showModalDos, setShowModalDos] = useState(false);

  return(
    <Layout 
        descritpion={pageContext.data.descripcionDeLaLista} 
        title={pageContext.data.tItulo} 
        subtitile={pageContext.data.subtitulo} 
        imgurl={pageContext.data.imagenDeFondo.file.url}
        metaTitle={pageContext.data.metaTitle}
        metaKeywords={pageContext.data.metaKeywords}
        metaDescripcin={pageContext.data.metaDescripcin}
    >
        <div className="template-type-1">
            <Modal show={showModalUno} onHide={() => {setShowModalUno(!showModalUno)}}>
                <Modal.Header closeButton>
                    <Modal.Title>{QueryResultData.tituloDeLaImagen1}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <iframe width="100%" height="100%" src={QueryResultData.enlaceDeVideo1} title={pageContext.data.tItulo}></iframe>
                </Modal.Body>
            </Modal>

            <Modal show={showModalDos} onHide={() => {setShowModalDos(!showModalDos)}}>
                <Modal.Header closeButton>
                    <Modal.Title>{QueryResultData.tituloDeLaImagen2}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <iframe width="100%" height="100%" src={QueryResultData.enlaceDeVideo2} title={pageContext.data.tItulo}></iframe>
                </Modal.Body>
            </Modal>

            <ShareButton url={location.pathname} colour={'rgb(200, 165, 60)'}/>
            
            <BackgroundImage className="background-image" fluid={QueryResultData.imagenDeFondo.fluid}>
                <div className="gradient-image">
                    <div className="template-container">
                        <div className="template-subcontainer-1">
                            <h1 className="Garamond-Bold"><span className="text-decoration"></span>{QueryResultData.tItulo}</h1>
                            <div className="text-description-container">
                                {documentToReactComponents(QueryResultData.descripcion.json)}
                            </div>
                            <div className="videos-modal-container">
                                <div className="videos-modal-subcontainer-1">
                                    <div className="title-video-modal">
                                        <div className="icon-container"><img alt="Icono de video" src={VideoIcon}/></div>
                                        <div className="title-subcontainer">
                                            <p className="Garamond-Bold">{QueryResultData.tituloDeLaImagen1}</p>
                                        </div>
                                    </div>
                                    <div className="video-image-modal" onClick={() => {setShowModalUno(!showModalUno)}} onKeyDown={(ev) => {if(ev.keyCode === 13){setShowModalUno(!showModalUno)}}} role="button" tabIndex="0">
                                        <div className="gradient"></div>
                                        <Image fluid={QueryResultData.imagenUno.fluid} alt={QueryResultData.imagenUno?.description ? QueryResultData.imagenUno?.description : QueryResultData.imagenUno?.title}/>
                                    </div>
                                </div>
                                <div className="videos-modal-subcontainer-2">
                                    <div className="title-video-modal">
                                        <div className="icon-container"><img alt="Icono de video" src={VideoIcon}/></div>
                                        <div className="title-subcontainer">
                                            <p className="Garamond-Bold">{QueryResultData.tituloDeLaImagen2}</p>
                                        </div>                                        
                                    </div>
                                    <div className="video-image-modal" onClick={() => {setShowModalDos(!showModalDos)}} onKeyDown={(ev) => {if(ev.keyCode === 13){setShowModalDos(!showModalDos)}}} role="button" tabIndex="0">
                                        <div className="gradient"></div>
                                        <Image fluid={QueryResultData.imagenDos.fluid} alt={QueryResultData.imagenDos?.description ? QueryResultData.imagenDos?.description : QueryResultData.imagenDos?.title}/>
                                    </div>
                                </div>
                            </div>
                            {QueryResultData.mostrarDescripcionInferior === true &&
                                <div className="description">
                                    <div className="description-icon"><i className="fa fa-music"></i></div>
                                    <div className="description-text-container">{documentToReactComponents(QueryResultData.descripcionInferior.json)}</div>
                                </div>
                            }
                            


                            <div className="template-subcontainer-2 template-subcontainer-2-responsive">
                                <div className="template-list">
                                    {QueryResultData.ttuloDeLaLista === '-' ?
                                        null : 
                                        <h2 className="Garamond-Bold">{QueryResultData.ttuloDeLaLista}</h2>
                                    }
                                    <ul className={QueryResultData.ttuloDeLaLista === '-' ? "List-margin-top-out-title" : "List-margin-top-title"}>
                                        { 
                                            pageContext.data.lista.map((lista,i) => (
                                                <li key={i}>
                                                    <Link to={`/${normalize(QueryResultData.tItulo).toLocaleLowerCase().trim()}/${normalize(lista).toLocaleLowerCase().trim()}`} className="Garamond-Regular "><span>{i+1}</span> {lista}</Link>
                                                </li>
                                            )) 
                                        }  
                                    </ul>
                                    <div className="description-list-container">
                                        {
                                        !QueryResultData.habilitarTercerCarrusel ?
                                        <h1 className="description-list "><span className="text-decoration"></span>{QueryResultData.descripcionDeLaLista}</h1>
                                        :
                                        <Link className="description-list" to={`/${normalize(QueryResultData.tItulo).toLocaleLowerCase().trim()}/creditos-${normalize(QueryResultData.idParaEnlazar).toLocaleLowerCase().trim()}`}><span className="text-decoration"></span>
                                        <h3 className="Garamond-Bold">{QueryResultData.descripcionDeLaLista}</h3></Link> 
                                        }
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="template-subcontainer-2"> 
                            <div className="template-list">
                                {QueryResultData.ttuloDeLaLista === '-' ?
                                    null : 
                                    <h2 className="Garamond-Bold">{QueryResultData.ttuloDeLaLista}</h2>
                                }
                                <ul className={QueryResultData.ttuloDeLaLista === '-' ? "List-margin-top-out-title" : "List-margin-top-title"}>
                                    { 
                                        pageContext.data.lista.map((lista,i) => (
                                            <li key={i}>
                                                <Link to={`/${normalize(QueryResultData.tItulo).toLocaleLowerCase().trim()}/${normalize(lista).toLocaleLowerCase().trim()}`} className="Garamond-Regular "><span>{i+1}</span> {lista}</Link>
                                            </li>
                                        )) 
                                    }  
                                </ul>
                                <div className="description-list-container">
                                    {
                                    !QueryResultData.habilitarTercerCarrusel ?
                                    <h1 className="description-list "><span className="text-decoration"></span>{QueryResultData.descripcionDeLaLista}</h1>
                                    :
                                    <Link className="description-list" to={`/${normalize(QueryResultData.tItulo).toLocaleLowerCase().trim()}/creditos-${normalize(QueryResultData.idParaEnlazar).toLocaleLowerCase().trim()}`}><span className="text-decoration"></span>
                                    <h3 className="Garamond-Bold">{QueryResultData.descripcionDeLaLista}</h3></Link> 
                                    }
                                </div>
                            </div>
                        </div>
                        {QueryResultData.mostrarDescripcionInferior === true &&
                                <div className="description description-responsive">
                                    <div className="description-icon"><i className="fa fa-music"></i></div>
                                    <div className="description-text-container">{documentToReactComponents(QueryResultData.descripcionInferior.json)}</div>
                                </div>
                        }
                    </div>
                </div>
            </BackgroundImage>
        </div>
    </Layout>
  )
}
export default Template3part1
